<template>
<div id="internal">

  <div class="hidden-sm-and-down">
    <PageHeader/>
  </div>
  <div class="hidden-md-and-up">
    <m-top/>
  </div>

  <div class="hidden-xs-only">
    
    <DietSidebar dietMainPage="faq"/>
    <el-row type="flex" justify="center">
      <el-col :md="20" :lg="18" :xl="15">
        <div style="width:100%;max-width: 1284px; margin: 0 auto;">
          <div class="side-box" style="margin-bottom: 30px; position: relative; width: 100%;">
            <div class="title">メディカルダイエット</div>
            <div class="LINE_btn" @click="goHref('https://liff.line.me/1657916768-pJgN5enw/landing?follow=%40484tylso&lp=zFcyNv&liff_id=1657916768-pJgN5enw')">
              <div class="side-box">
                <img src="../../assets/SNS/line.png" style="height:50px">
                <div class="side-text-line">
                  <div style="font-size: 18px; text-align: center;">メディカルダイエット専用</div>
                  <div style="font-size: 18px; text-align: center;">お友達登録＋診療予約</div>
                </div>
              </div>
            </div>
          </div>

          

          <div class="separator">
              <div class="title_background">
                <div class="center-title" style="margin: 0 auto;">よくある質問</div>
              </div>

              <div class="text_background">
                <el-row type="flex" justify="center">
                  <el-col :md="20" :lg="18" :xl="21">
                    <div class="side-text">
                      <div class="Q">
                        保険証の提示は必要ですか？
                      </div>
                      <div class="A">
                        自由診療のため、特に必要はありません。
                      </div>

                      <div class="Q">
                        オンライン診療にどのくらい時間がかかりますか？
                      </div>
                      <div class="A">
                        カウンセリング、医師の診療を含め１５分ぐらいです。
                      </div>

                      <div class="Q">
                        診療予約はいつまで出来ますか？
                      </div>
                      <div class="A">
                        2週間先の診療日までとなります
                      </div>

                      <div class="Q">
                        診療予約の変更・キャンセルはいつまでできますか？
                      </div>
                      <div class="A">
                        ご予約日の前日までとなります
                      </div>

                      <div class="Q">
                        <div>
                          初診でもオンライン診療は可能ですか？
                        </div>
                      </div>
                      <div class="A">
                        <div>
                          はい、初診の患者様でもオンライン診療にてGLP-1をご注文いただけます。
                          <br>こちらの診療予約から24時間予約を受け付けております。
                        </div>
                      </div>

                      <div class="Q">
                        <div>
                          GLP-1の薬(リベルサス)に副作用はありますか？
                          </div>
                      </div>
                      <div class="A">
                        <div>
                          副作用としては、薬を飲み始めた際に、下記のような症状が報告されております。
                          <br>胃もたれ、胸やけ、便秘、軽い吐き気、膨満感、倦怠感、など。
                          <br>副作用には、個人差がありますが、治療を継続していくことで副作用は軽減しています。
                          <br>ごくまれに、低血糖状態になり、冷や汗や立ちくらみが起こることがありますが、その際は糖分を摂取するようにしてください。症状はすぐに落ち着きます。
                        </div>
                      </div>

                      <div class="Q">
                        <div>
                          GLP-1の薬(リベルサス)に誰でも治療できますか？
                        </div>
                      </div>
                      <div class="A">
                        <div>
                          以下に該当される方は治療出来ません。​
                          <div style="height: 15px;"></div>
                          <ol>
                            <li>
                              膵炎などの膵臓疾患の既往、甲状腺疾患の既往、重度の胃腸障害（胃潰瘍、炎症性腸疾患など）のある方
                            </li>
                            <li>
                              低血糖を起こす可能性が高い以下の状態の方
                              <ol>
                                <li>
                                  脳下垂体機能不全又は副腎機能不全
                                </li>
                                <li>
                                  栄養不良状態、飢餓状態、食事摂取量の不足
                                </li>
                                <li>
                                  激しい筋肉運動
                                </li>
                                <li>
                                  過度のアルコール摂取者
                                </li>
                              </ol>
                            </li>
                            <li>
                              糖尿病の方
                            </li>
                            <li>
                              妊娠中または2か月以内に妊娠する予定の方、未成年の方、60歳以上の方
                            </li>
                          </ol>
                        </div>
                      </div>

                      <div class="Q">
                        これまでダイエットに失敗した人でも効果が得られますか？
                      </div>
                      <div class="A">
                        <div>
                          GLP-1リベルサスを内服することで、食欲を抑制し、長時間満腹感が続きますので食事の量が減少します。あとは、普段通り日常生活を送ることで消費カロリーと摂取カロリーの差によりダイエットが進んでいきます。これまでに食事制限や無理な運動をして続かずにダイエットが上手くいかなかった方も効果が期待できる治療方法です。
                        </div>
                      </div>

                      <div class="Q">
                        GLP-1を使ったダイエットは安全ですか？
                      </div>
                      <div class="A">
                        <div>GLP-1ホルモンは、人の体に実際に存在しているホルモンです。すでに世界各国では肥満治療として実施されている安全性のある治療方法です。GLP-1の作用としては、食後などに血糖値が上昇した時のみにインスリンの分泌を促進する作用があるので、過剰にインスリン分泌を促進し、低血糖になるといったリスクもありません。</div>
                      </div>

                      <div class="Q">
                        <div>服用後いつから体重が減少し始めますか？</div>
                      </div>
                      <div class="A">
                        <div>リベルサスでは早ければ服用後3ヶ月程度で、体重減少の効果が見込めれます。
                        <br>服用を途中でやめてしまうと、食欲低減などの効果はなくなってしまいます。
                        <br>​治療中止後にリバウンドする可能性があることに注意が必要です。</div>
                      </div>

                      <div class="Q">
                        <div>診療後、お薬はどれぐらいで届きますか？</div>
                      </div>
                      <div class="A">
                        <div>オンライン診療の場合、関東近辺の方は4日後程でお届け出来ると思います。
                          <br>来院診療の場合はその場でお渡しできます。</div>
                      </div>
                    </div>
                  </el-col>
                </el-row>
              </div>
                

          </div>
        </div>


      </el-col>
    </el-row>
    <PageFooter/>
  </div>

  <div class="hidden-sm-and-up">
    <MDietSidebar/>
    <div class="side-box" style="margin-bottom: 30px;">
          <div class="title">メディカルダイエット</div>
      </div>

    

    <div class="separator">
      <div class="detail1" style="margin: 0 auto;">

      <div class="m_LINE_btn"  @click="goHref('https://liff.line.me/1657916768-pJgN5enw/landing?follow=%40484tylso&lp=LzuXdt&liff_id=1657916768-pJgN5enw')">
        <div class="side-box">
          <img src="../../assets/SNS/line.png" style="height:50px;margin-left: 20px">
          <div class="side-text-line">
            <div style="font-size: 15px; text-align: center; width: 100%;">メディカルダイエット専用</div>
            <div style="font-size: 15px; text-align: center; width: 100%;">お友達登録＋診療予約</div>
          </div>
        </div>
      </div>

      <div class="m_title_background">
        <div class="m-center-title" style="margin: 0 auto;">よくある質問</div>
      </div>

      <div class="text_background">
        <div class="m_side-text">
              <div class="Q">
                保険証の提示は必要ですか？
              </div>
              <div class="A">
                自由診療のため、特に必要はありません。
              </div>

              <div class="Q">
                オンライン診療にどのくらい時間がかかりますか？
              </div>
              <div class="A">
                カウンセリング、医師の診療を含め１５分ぐらいです。
              </div>

              <div class="Q">
                診療予約はいつまで出来ますか？
              </div>
              <div class="A">
                2週間先の診療日までとなります
              </div>

              <div class="Q">
                診療予約の変更・キャンセルはいつまでできますか？
              </div>
              <div class="A">
                ご予約日の前日までとなります
              </div>

              <div class="Q">
                <div>
                  初診でもオンライン診療は可能ですか？
                </div>
              </div>
              <div class="A">
                <div>
                  はい、初診の患者様でもオンライン診療にてGLP-1をご注文いただけます。
                  <br>こちらの診療予約から24時間予約を受け付けております。
                </div>
              </div>

              <div class="Q">
                <div>
                  GLP-1の薬(リベルサス)に副作用はありますか？
                  </div>
              </div>
              <div class="A">
                <div>
                  副作用としては、薬を飲み始めた際に、下記のような症状が報告されております。
                  <br>胃もたれ、胸やけ、便秘、軽い吐き気、膨満感、倦怠感、など。
                  <br>副作用には、個人差がありますが、治療を継続していくことで副作用は軽減しています。
                  <br>ごくまれに、低血糖状態になり、冷や汗や立ちくらみが起こることがありますが、その際は糖分を摂取するようにしてください。症状はすぐに落ち着きます。
                </div>
              </div>

              <div class="Q">
                <div>
                  GLP-1の薬(リベルサス)に誰でも治療できますか？
                </div>
              </div>
              <div class="A">
                <div>
                  以下に該当される方は治療出来ません。​
                  <div style="height: 15px;"></div>
                  <ol>
                    <li>
                      膵炎などの膵臓疾患の既往、甲状腺疾患の既往、重度の胃腸障害（胃潰瘍、炎症性腸疾患など）のある方
                    </li>
                    <li>
                      低血糖を起こす可能性が高い以下の状態の方
                      <ol>
                        <li>
                          脳下垂体機能不全又は副腎機能不全
                        </li>
                        <li>
                          栄養不良状態、飢餓状態、食事摂取量の不足
                        </li>
                        <li>
                          激しい筋肉運動
                        </li>
                        <li>
                          過度のアルコール摂取者
                        </li>
                      </ol>
                    </li>
                    <li>
                      糖尿病の方
                    </li>
                    <li>
                      妊娠中または2か月以内に妊娠する予定の方、未成年の方、60歳以上の方
                    </li>
                  </ol>
                </div>
              </div>

              <div class="Q">
                これまでダイエットに失敗した人でも効果が得られますか？
              </div>
              <div class="A">
                <div>
                  GLP-1リベルサスを内服することで、食欲を抑制し、長時間満腹感が続きますので食事の量が減少します。あとは、普段通り日常生活を送ることで消費カロリーと摂取カロリーの差によりダイエットが進んでいきます。これまでに食事制限や無理な運動をして続かずにダイエットが上手くいかなかった方も効果が期待できる治療方法です。
                </div>
              </div>

              <div class="Q">
                GLP-1を使ったダイエットは安全ですか？
              </div>
              <div class="A">
                <div>GLP-1ホルモンは、人の体に実際に存在しているホルモンです。すでに世界各国では肥満治療として実施されている安全性のある治療方法です。GLP-1の作用としては、食後などに血糖値が上昇した時のみにインスリンの分泌を促進する作用があるので、過剰にインスリン分泌を促進し、低血糖になるといったリスクもありません。</div>
              </div>

              <div class="Q">
                <div>服用後いつから体重が減少し始めますか？</div>
              </div>
              <div class="A">
                <div>リベルサスでは早ければ服用後3ヶ月程度で、体重減少の効果が見込めれます。
                <br>服用を途中でやめてしまうと、食欲低減などの効果はなくなってしまいます。
                <br>​治療中止後にリバウンドする可能性があることに注意が必要です。</div>
              </div>

              <div class="Q">
                <div>診療後、お薬はどれぐらいで届きますか？</div>
              </div>
              <div class="A">
                <div>オンライン診療の場合、関東近辺の方は4日後程でお届け出来ると思います。
                  <br>来院診療の場合はその場でお渡しできます。</div>
              </div>
        </div>
      </div>
          
      </div>
    </div>


    <m-departs/>
    <m-footer/>
  </div>
</div>
</template>

<script>
import PageHeader from "@/components/PageHeader";
import PageFooter from "@/components/PageFooter";
import DepartmentAside from "@/components/DepartmentAside";
import MTop from "../../components/m/MTop";
import MFooter from "../../components/m/MFooter";
import MDeparts from "../../components/m/MDeparts";
import DietSidebar from "@/components/DietSidebar";
import MDietSidebar from "../../components/m/MDietSidebar";
export default {
  name: "FAQ",
  components: {MDeparts, MFooter, MTop, DepartmentAside, PageFooter, PageHeader,DietSidebar,MDietSidebar},
  methods:{
    goHref(path) {
      window.location.href = path;
    },
  }
}
</script>

<style scoped>

/* PC */
.hidden-sm-and-down .img {
  margin-top: 30px;
}
.detail1 {
  margin: 40px 20px;
}
.content1 {
  margin-top: 30px;
}
.content1 .title1 {
  color: #aa7b22;
  font-size: 22px;
  margin-bottom: 10px;
  margin-top: 40px;
}
.content1 .text1 {
  color: #0a4a6e;
  font-size: 18px;
  /* line-height: 1.5; */
}
.other {
  color: #535353;
  font-size: 14px;
  line-height: 1.5;
}

.seperate_line{
  height:5px;
  border-top-color: #CBB8B2;
  border-top-width: 2px;
  border-top-style: solid;
  margin: 0 auto;
  width: 90%;
}

/* SP */
.m-main {
  background-color: #FFFFFF;
  border-top: 2px solid #CBB8B2;
  border-bottom: 2px solid #CBB8B2;
  padding: 2rem 0;
}
.m-title {
  color: #535353;
  font-size: 24px;
  text-align: center;
  margin: 2rem 0;
}
.m-detail {
  background-color: #FFFFFF;
  padding: 0 1rem;
}
.m-subtitle {
  color: #CBB8B2;
  font-size: 18px;
  padding: 2rem 0 1rem;
}
.m-content {
  line-height: 24px;
  color: #535353;
}

.center-title{
  color: #FFFFFF;
  font-size: 50px;
  text-align: center;
  width: 100%;
  margin-bottom: 10px;
}
.m-center-title{
  color: #FFFFFF;
  font-size: 25px;
  text-align: center;
  width: 100%;
  margin-bottom: 10px;
}

.LINE_btn{
  background-color: #20ce88;
  border-radius: 5px; 
  color: #FFFFFF;
  width:300px;
  height:50px;
  position: absolute;
  right: 0px;
  box-shadow: 5px 5px 10px #42859b,
  3px 3px 0px #20ce8878;
  cursor: pointer;
  transition: 150ms;
}

.LINE_btn:hover{
  background-color: #ffffff;
  color: #20ce88;
  box-shadow: 5px 5px 10px #42859b66,
  3px 3px 0px #20ce881b;
}
.m_LINE_btn{
  background-color: #20ce88;
  color: #FFFFFF;
  border-radius: 8px;
  width:80%;
  height:50px;
  margin-top: 15px;
  margin-bottom: 15px;
  margin-left: 4%;

  box-shadow: 5px 5px 10px #42859b,
  3px 3px 0px #20ce8878;
  ;
}
.m_LINE_btn:active{
  background-color: #ffffff;
  color: #20ce88;
  border-radius: 8px;
  width:350px;
  height:50px;
  margin-top: 15px;
  margin-bottom: 15px;
  margin-left: 4%;

  box-shadow: 5px 5px 10px #42859b,
  3px 3px 0px #20ce8878;
  ;
}

.banner_slogan{

  text-align: center;
  color: #326686;
  text-shadow: 1px 2px 0px #e9d5ce6b,
    0px 2px 3px #326686c4
    ;
  width: 100%;
}

.slogan_small{
  font-size: 15px;
  margin-top: 5px;
}

.slogan_big{
  font-size: 18px;
  margin-top: 15px;
}

.slogan_big_l{
  font-size: 30px;
  margin-top: 15px;
}

.slogan_large{
  font-size: 50px;  
}

.m_slogan_small{
  font-size: 5px;
  margin-top: 5px;
}

.m_slogan_big{
  font-size: 10px;
  margin-top: 5px;
}

.m_slogan_big_l{
  font-size: 12px;
  margin-top: 5px;
}

.m_slogan_large{
  font-size: 20px;  
}

.font_border{
  text-align: center;
  color: #326686;
  text-shadow: 
    0px 1px 4px #f5efe3,
    0px -1px 4px #f5efe3,
    1px 0px 4px #f5efe3,
    -1px 0px 4px #f5efe3;
  width: 100%;
}

.img_banner{
  position: relative;
  height: auto;
}


.bg_blue{
  background-color: #326686;
  width: 100%;
  height: auto;
  padding-top: 50px;
  padding-bottom: 50px;
}

.ryoukin_hint{
  color: #F71B1B;
  font-size: 16px;
  text-align: right;
  padding-right: 50px;
}

.title {
      font-size: 30px;
      letter-spacing: 5px;
      padding-top: 20px;
      padding-left: 20px;
    }

    .separator {
      color: #CBB8B2;
      width: 100%;
      margin: 0 auto;
      border-top: 4px solid;
      border-bottom: 4px solid;
    }

.side-img{
height: fit-content;
width:fit-content;
vertical-align:middle;
margin-left: 30px;
margin-right: 30px;
}

.side-text{
height: fit-content;
margin-left: 50px;
margin-right: 50px;
margin-bottom: auto;
}

.m_side-text{
height: fit-content;
margin-left: 7%;
margin-right: 7%;
width: 86%;
margin-bottom: auto;
}

.side-text-line{
  height: fit-content;
}



.side-box{
  display: inline-flex;
  justify-content: space-between;
  align-items:center;
  vertical-align: top;
  height: fit-content;
}

.side-line-highlight{
  background-color: #CBB8B2;
  width:6px;
  height: 25px;
  margin-bottom: 10px;
  margin-right: 10px;
}

.title_background{
  background-color: #b7bfc4;
  width: 100%;
  height:150px;
  display: flex;
  align-items: center;
}

.m_title_background{
  background-color: #b7bfc4;
  width: 100%;
  height:75px;
  display: flex;
  align-items: center;
}

.text_background{
  width: 100%;
  height:fit-content;
  background-color: #f6f9f5;
  padding-top: 25px;
  padding-bottom: 25px;
  margin-top: 5px;

}

.text_title_top{
  font-size: 32px;
  color: #0a4a6e;
  
}

.Q{
  font-size: 22px;
  color: #0a4a6e;
  margin-top: 20px;
  display: flex;
  line-height: 1.5;
}

.Q::before{
  color: #1285c6;
  content: "Q.";
  margin-right: 10px;
  display: inline-block;
  vertical-align: middle;
}

.A{
  font-size: 22px;
  color: #0a4a6e;
  display: flex;
  line-height: 1.5;
  
}

.A::before{
  color: #ff4040;
  content: "A.";
  margin-right: 10px;
  display: inline-block;
  vertical-align: middle;
}

ol {
  counter-reset: section; 
  font-size: 16px;

  list-style-type: none;
}

li{
  margin-top: 5px;
}

li::before {
  counter-increment: section; 
  
  content: counters(section, ".") " ";

}

ol > li {
    margin-left: 20px;
}


</style>
